import { action, autorun, computed, extendObservable, decorate, reaction } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";
import _ from "lodash";
import moment from "moment";

class AssignDigitalTicketsStore {
  constructor(authStore, commonStore, compTixApi, routerStore, loadingStore, alertStore, eventReservationsStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;
    this.eventReservationsStore = eventReservationsStore;

    this.defaults = {
      selectedUserId: -1,
      selectedRequestId: -1,
      showAssignTicketsModal: false,
      showParkingPasses: false,
      requestId: null,
      request: {},
      allTickets: [],
      parkingPasses: [],
      selectedTickets: [],
      account: "",
      sortFilters: {
        direction: "ASC",
        key: "section"
      },
      parkingSortFilters: {
        direction: "ASC",
        key: "section"
      },
      sending: false,
      gameInfo: {},
      allowRefresh: false
    };

    extendObservable(this, {
      selectedUserId: this.defaults["selectedUserId"],
      selectedRequestId: this.defaults["selectedRequestId"],
      showAssignTicketsModal: this.defaults["showAssignTicketsModal"],
      showParkingPasses: this.defaults["showParkingPasses"],
      requestId: this.defaults["requestId"],
      request: this.defaults["request"],
      allTickets: this.defaults["allTickets"],
      parkingPasses: this.defaults["parkingPasses"],
      selectedTickets: this.defaults["selectedTickets"],
      sortFilters: this.defaults["sortFilters"],
      parkingSortFilters: this.defaults["parkingSortFilters"],
      sending: this.defaults["sending"],
      account: this.defaults["account"],
      setSelectedUserId: action(userId => {
        this.selectedUserId = userId;
      }),
      setSelectedRequestId: action(selectedRequestId => {
        this.selectedRequestId = selectedRequestId;
      }),
      setShowAssignTicketsModal: action(value => {
        this.showAssignTicketsModal = value;
      }),
      setRequestId: action(value => {
        this.requestId = value;
      }),
      setRequest: action(value => {
        this.request = value;
      }),
      toggleShowParkingPasses: action(() => {
        this.showParkingPasses = !this.showParkingPasses;
      }),
      setShowParking: action(value => {
        this.showParkingPasses = value;
      }),
      setAccount: action(value => {
        this.account = value;
      }),
      setAllTickets: action(data => {
        this.allTickets = data;
      }),
      setParkingTickets: action(data => {
        this.parkingPasses = data;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      setParkingSortDirection: action((col, direction) => {
        this.parkingSortFilters.key = col;
        this.parkingSortFilters.direction = direction;
      }),
      setSending: action(sending => {
        this.sending = sending;
      }),
      setAllowRefresh: action(allowRefresh => {
        this.allowRefresh = allowRefresh;
      }),
      updateTicketSelected: action(rows => {
        rows.forEach(row => {
          let index = this.allTickets.findIndex(r => r.ticketID === row.ticketID);
          if (index > -1) {
            let copy = Object.assign({}, this.allTickets[index]);
            copy.isSelected = !copy.isSelected;
            this.allTickets[index] = copy;
          }
        });
      }),
      updateParkingTicketSelected: action(rows => {
        rows.forEach(row => {
          let index = this.parkingPasses.findIndex(r => r.ticketID === row.ticketID);
          if (index > -1) {
            let copy = Object.assign({}, this.parkingPasses[index]);
            copy.isSelected = !copy.isSelected;
            this.parkingPasses[index] = copy;
          }
        });
      }),
      removeSelectedTicket: action(ticket => {
        this.selectedTickets.remove(ticket);
      }),
      resetStore: action(() => {
        this.sortFilters = this.defaults["sortFilters"];
        this.parkingSortFilters = this.defaults["parkingSortFilters"];
        this.selectedUserId = this.defaults["selectedUserId"];
        this.allTickets = this.defaults["allTickets"];
        this.parkingPasses = this.defaults["parkingPasses"];
        this.selectedTickets = this.defaults["selectedTickets"];
        this.account = this.defaults["account"];
      })
    });

    reaction(
      () => this.showAssignTicketsModal,
      () => {
        if (!this.showAssignTicketsModal) {
          this.setAllTickets([]);
          this.setParkingTickets([]);
          this.setSending(false);
        }
        if (this.showAssignTicketsModal && this.requestId != null) {
          this.setSending(false);
          this.compTixApi.getRefreshInformation(this.commonStore.currentOrg.orgId).then(refreshInfo => {
            this.setAllowRefresh(moment(refreshInfo.lastRefresh).isBefore(moment().add(-60, "minutes")));
            this.compTixApi.getTicketRequest(this.requestId).then(data => {
              this.setRequest(data);
              this.loadingStore.setLoading(true);
              this.gameInfo = data;

              let statsId;
              if (this.authStore.isBoxOfficeAdmin) {
                statsId = this.gameInfo.requesterOrg.statsId || this.eventReservationsStore.game.teams.home.team.id;
              } else {
                statsId = this.commonStore.currentOrg.statsId || this.eventReservationsStore.game.teams.home.team.id;
              }
              this.compTixApi
                .getAvailableTickets(
                  statsId,
                  data.gamePk,
                  data.requesterOrg.allStarTeam
                    ? 24
                    : data.requesterOrg.statsId == null
                    ? 0
                    : data.venueSeason.venueSeasonKeyId.orgId,
                  false,
                  this.requestId
                )
                .then(tickets => {
                  const tick = [];
                  const park = [];
                  for (const ticket of tickets.tickets) {
                    ticket.id = ticket.ticketID;
                    if (ticket.parking) {
                      park.push(ticket);
                    } else {
                      tick.push(ticket);
                    }
                  }
                  this.setAllTickets(tick);
                  this.setParkingTickets(park);
                  this.setAccount(tickets.account);
                  this.loadingStore.setLoading(false);
                });
            });
          });
        }
      }
    );
  }

  forwardTickets = () => {
    this.loadingStore.setLoading(true);
    let statsId;
    if (this.authStore.isBoxOfficeAdmin) {
      statsId = this.gameInfo.requesterOrg.statsId || this.eventReservationsStore.game.teams.home.team.id;
    } else {
      statsId = this.commonStore.currentOrg.statsId || this.eventReservationsStore.game.teams.home.team.id;
    }
    this.compTixApi
      .forwardTickets(
        this.request.deliveryEmail,
        statsId,
        this.request.requesterOrg.allStarTeam
          ? 24
          : this.request.requesterOrg.statsId == null
          ? 0
          : this.request.venueSeason.venueSeasonKeyId.orgId,
        this.selectedSeats.map(t => t.ticketID).concat(this.selectedParkingPasses.map(t => t.ticketID)),
        this.request.ticketRequestId,
        this.request.deliveryMobilePhone,
        this.request.countryCode,
        {
          recipientEmail: this.request.deliveryEmail,
          recipientFirstName: this.request.recipientFirstName,
          recipientLastName: this.request.recipientLastName
        },
        this.request.gamePk
      )
      .then(data => {
        this.loadingStore.setLoading(false);
        this.setShowAssignTicketsModal(false);
        this.setShowParking(false);
        this.resetStore();
        if (data.alerts[0].severity === "SUCCESS") {
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Ticket(s) sent"
          });
          this.eventReservationsStore.reservations.find(
            res => res.ticketRequestId === data.entity.requestId
          ).digitalTicketDelivery = data.entity;
          this.eventReservationsStore.setReservations(this.eventReservationsStore.reservations);
        }
        this.setSending(false);
      });
  };

  refreshTickets = () => {
    this.loadingStore.setLoading(true);
    let statsId;
    if (this.authStore.isBoxOfficeAdmin) {
      statsId = this.gameInfo.requesterOrg.statsId || this.eventReservationsStore.game.teams.home.team.id;
    } else {
      statsId = this.commonStore.currentOrg.statsId || this.eventReservationsStore.game.teams.home.team.id;
    }
    this.compTixApi
      .getAvailableTickets(
        statsId,
        this.gameInfo.gamePk,
        this.gameInfo.requesterOrg.statsId == null ? 0 : this.gameInfo.venueSeason.venueSeasonKeyId.orgId,
        true,
        this.requestId
      )
      .then(tickets => {
        const tick = [];
        const park = [];
        for (const ticket of tickets.tickets) {
          ticket.id = ticket.ticketID;
          if (ticket.parking) {
            park.push(ticket);
          } else {
            tick.push(ticket);
          }
        }
        this.setAllTickets(tick);
        this.setParkingTickets(park);
        this.loadingStore.setLoading(false);
      });
  };

  get displayableTickets() {
    return this.sort(this.allTickets.toJSON(), this.sortFilters);
  }

  get displayableParkingPasses() {
    return this.sort(this.parkingPasses.toJSON(), this.parkingSortFilters);
  }

  get selectedSeats() {
    return this.allTickets.toJSON().filter(t => t.isSelected);
  }

  get selectedParkingPasses() {
    return this.parkingPasses.toJSON().filter(t => t.isSelected);
  }

  get venueSeatmapUrl() {
    return this.eventReservationsStore.game.venueSeatmapUrl;
  }

  sort(tickets, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(tickets, [ticket => _.toLower(ticket["section"])]);
    } else if (direction === "ASC") {
      return _.sortBy(tickets, [ticket => _.toLower(ticket[searchFilters.key])]);
    } else if (direction === "DESC") {
      return _.sortBy(tickets, [ticket => _.toLower(ticket[searchFilters.key])]).reverse();
    } else {
      return tickets;
    }
  }
}

decorate(AssignDigitalTicketsStore, {
  displayableTickets: computed,
  selectedSeats: computed,
  selectedParkingPasses: computed
});

export default AssignDigitalTicketsStore;
