import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class RequestFooter extends React.Component {
  render() {
    const { requestStore, authStore } = this.props.rootStore;
    return (
      <StickyFooterButtons startsOpen={authStore.userData.org.orgId === 31}>
        <PrimaryButton
          onClick={requestStore.saveRequest}
          disabled={!requestStore.isValidRequest || requestStore.isSending || requestStore.game.locked}
          id="submit-request"
        >
          Submit Request
        </PrimaryButton>
        <LightButton onClick={requestStore.cancel} id="cancel">
          Cancel
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(RequestFooter));
