import React from "react";
import { inject, observer } from "mobx-react";
import RequestDateFormatter from "../../elements/tables/RequestDateFormatter";
import EventReservationsAccordionMobile from "./EventReservationsAccordionMobile";
import EventReservationsEditIconFormatter from "./EventReservationsEditIconFormatter";
import DeleteEventReservationsFormatter from "./DeleteEventReservationsFormatter";
import { AccordionTableWrapper, ButtonLink, LightButton } from "best-common-react";
import styled from "styled-components";
import NumberFormatter from "../../../utilities/NumberFormatter";
import AssignFormatter from "../../../utilities/AssignFormatter";
const GamesSelectedContainer = styled.div`
  height: 1.5rem;
`;

const GamesSelected = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme["dark-grey"]};
  font-style: italic;
`;

class EventReservationsTable extends React.Component {
  constructor(props) {
    super(props);
    this.eventReservationsStore = props.rootStore.eventReservationsStore;
    this.commonStore = props.rootStore.commonStore;
    this.cols = [
      {
        key: "id",
        name: "",
        width: 35,
        formatter: EventReservationsEditIconFormatter,
        locked: true
      },
      {
        key: "id1",
        name: "",
        width: 35,
        formatter: DeleteEventReservationsFormatter,
        locked: true
      },
      {
        key: "requester",
        name: "Requester",
        width: 180,
        sortable: true,
        locked: true
      },
      { key: "recipient", name: "Recipient", width: 140, sortable: true },
      { key: "relation", name: "Relation", width: 80, sortable: true },
      { key: "sectionAndPrice", name: "Section", width: 175, sortable: true },
      { key: "quantity", name: "Quantity", width: 100, sortable: true, formatter: NumberFormatter },
      { key: "id2", name: "", sortable: false, formatter: AssignFormatter },
      { key: "seats", name: "Seats", sortable: false },
      // Remove until we figure out how to pull the accepted flag speedily { key: "accepted", name: "Accepted", sortable: false, formatter: CheckFormatter },
      { key: "pickupLocation", name: "Pickup Location", width: 180, sortable: true },
      { key: "departmentType", name: "Department", width: 100, sortable: true },
      {
        key: "requestDate",
        name: "Request Date",
        width: 220,
        sortable: true,
        formatter: RequestDateFormatter
      },
      { key: "comments", name: "Comments", sortable: true }
    ];
  }

  onRowsSelected = rows => {
    this.eventReservationsStore.addSelectedReservations(rows.map(row => row.row));
  };

  onRowsDeselected = rows => {
    this.eventReservationsStore.removeSelectedReservations(rows.map(row => row.row));
  };

  render() {
    return (
      <div>
        <GamesSelectedContainer className="row mt-3 mb-4">
          {this.eventReservationsStore.selectedReservations.length ? (
            <GamesSelected className="col">
              <div className="mt-4">
                {this.eventReservationsStore.selectedReservations.length} selected out of{" "}
                {this.eventReservationsStore.currentReservations.length} of{" "}
                {this.eventReservationsStore.totalReservationCount} showing
              </div>
            </GamesSelected>
          ) : (
            <GamesSelected className="col">
              <div className="mt-4">
                {this.eventReservationsStore.currentReservations.length} of{" "}
                {this.eventReservationsStore.totalReservationCount} showing
              </div>
            </GamesSelected>
          )}
          <GamesSelected className="pr-1  mb-4">
            <LightButton className="mr-2" onClick={() => this.eventReservationsStore.getReservations()}>
              Refresh Reservations
            </LightButton>
          </GamesSelected>
        </GamesSelectedContainer>
        <AccordionTableWrapper
          id="event-reservations-table"
          data={this.eventReservationsStore.currentReservations}
          columns={this.cols}
          accordion={EventReservationsAccordionMobile}
          sortFunc={this.eventReservationsStore.setSortDirection}
          sortColumn={this.eventReservationsStore.sortFilters["key"]}
          sortDirection={this.eventReservationsStore.sortFilters["direction"]}
          height={400}
          rowSelection={{
            showCheckbox: true,
            onRowsSelected: this.onRowsSelected,
            onRowsDeselected: this.onRowsDeselected,
            selectBy: {
              isSelectedKey: "isSelected"
            },
            enableShiftSelect: true
          }}
        />
      </div>
    );
  }
}

export default inject("rootStore")(observer(EventReservationsTable));
