import React, { useState } from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton,
  ButtonLink,
  BestTable
} from "best-common-react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import CheckFormatter from "../elements/CheckFormatter";
import LoadingSpinner from "../loading/LoadingSpinner";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import moment from "moment";

const TicketCount = styled.span`
  color: ${props => (props.filled ? props.theme["green"] : props.theme["slate-grey"])};
  font-style: italic;
  font-size: 0.8rem;
`;

const TicketColumns = [
  {
    name: "Section",
    key: "section",
    sortable: true,
    width: 125
  },
  {
    name: "Row",
    key: "row",
    sortable: true,
    width: 100
  },
  {
    name: "Seat Number",
    key: "seat",
    sortable: true,
    width: 125
  },
  {
    name: "Price",
    key: "price",
    sortable: true,
    width: 125
  },
  {
    name: "Type",
    key: "provider",
    sortable: true,
    width: 100
  },
  {
    name: "Able To Forward",
    key: "forwardable",
    sortable: true,
    width: 120,
    cellClass: "text-center",
    formatter: CheckFormatter
  }
];

const ParkingColumns = [
  {
    name: "Parking Lot",
    key: "section",
    sortable: true,
    width: 125
  },
  {
    name: "Price",
    key: "price",
    sortable: true,
    width: 125
  },
  {
    name: "Type",
    key: "provider",
    sortable: true,
    width: 100
  },
  {
    name: "Able To Forward",
    key: "forwardable",
    sortable: true,
    width: 120,
    cellClass: "text-center",
    formatter: CheckFormatter
  }
];

class AssignDigitalTicketsModal extends React.Component {
  render() {
    const { assignDigitalTicketsStore } = this.props.rootStore;
    const rowSelected = rows => {
      assignDigitalTicketsStore.updateTicketSelected(rows);
    };
    const parkingRowSelected = rows => {
      assignDigitalTicketsStore.updateParkingTicketSelected(rows);
    };
    return (
      <Modal
        show={assignDigitalTicketsStore.showAssignTicketsModal}
        onClose={() => {}}
        size="lg"
        id="assign-digital-tickets-modal"
      >
        <ModalHeaderWithTitleAndClose
          title="Available tickets"
          close={() => {
            assignDigitalTicketsStore.setShowAssignTicketsModal(false);
            assignDigitalTicketsStore.setShowParking(false);
            assignDigitalTicketsStore.resetStore();
          }}
        />
        <ModalBody>
          <LoadingSpinner size={10} />
          <div>
            <b>From: </b> {assignDigitalTicketsStore.request?.requesterUser?.lastName},
            {assignDigitalTicketsStore.request?.requesterUser?.firstName}
          </div>
          <div>
            <b>To: </b> {assignDigitalTicketsStore.request?.recipientLastName},
            {assignDigitalTicketsStore.request?.recipientFirstName}
          </div>
          <div className="mt-4">
            <b>Account: </b> {assignDigitalTicketsStore.account}
          </div>
          <div className="seatmap-accordion mt-1" id="seatmapAccordion" style={{ marginBottom: 1 }}>
            <div className="card">
              <div className="card-header" id="headingOne" style={{ padding: 0 }}>
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Seatmap
                  </button>
                </h2>
              </div>

              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#seatmapAccordion">
                <div className="card-body">
                  <TransformWrapper>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <div className="tools">
                          <button onClick={() => zoomIn()}>+</button>
                          <button onClick={() => zoomOut()}>-</button>
                          <button onClick={() => resetTransform()}>x</button>
                        </div>
                        <TransformComponent>
                          <img
                            alt="No available seat map"
                            src={assignDigitalTicketsStore.venueSeatmapUrl}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-end">
              <TicketCount
                filled={assignDigitalTicketsStore.selectedSeats.length === assignDigitalTicketsStore.request.quantity}
              >
                {assignDigitalTicketsStore.selectedSeats.length} of {assignDigitalTicketsStore.request.quantity}{" "}
                selected
              </TicketCount>
            </div>
            <BestTable
              columns={TicketColumns}
              data={assignDigitalTicketsStore.displayableTickets}
              rowDisabledKey="disabled"
              rowStylesGetter={(index, value) => {
                if (value?.disabled) {
                  return { background: "#f5f7fa" };
                } else {
                  return {};
                }
              }}
              sortFunction={assignDigitalTicketsStore.setSortDirection}
              sortColumn={assignDigitalTicketsStore.sortFilters["key"]}
              sortDirection={assignDigitalTicketsStore.sortFilters["direction"]}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: rowSelected,
                onRowsDeselected: rowSelected,
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true,
                canSelect: value => {
                  return (
                    value.forwardable &&
                    (assignDigitalTicketsStore.selectedSeats.length < assignDigitalTicketsStore.request.quantity ||
                      value.isSelected)
                  );
                }
              }}
              selectAll={false}
              maxTableHeight={250}
            />
          </div>

          <div className="d-flex justify-content-end mt-2">
            <ButtonLink
              onClick={() => {
                assignDigitalTicketsStore.toggleShowParkingPasses();
              }}
            >
              {assignDigitalTicketsStore.showParkingPasses ? "Hide Parking Passes" : "Show Parking Passes"}
            </ButtonLink>
          </div>
          <div className="mt-2" hidden={!assignDigitalTicketsStore.showParkingPasses}>
            <div className="d-flex justify-content-end">
              <TicketCount filled={false}>
                {assignDigitalTicketsStore.selectedParkingPasses.length} selected
              </TicketCount>
            </div>
            <BestTable
              columns={ParkingColumns}
              data={assignDigitalTicketsStore.displayableParkingPasses}
              rowDisabledKey="disabled"
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: parkingRowSelected,
                onRowsDeselected: parkingRowSelected,
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true
              }}
              selectAll={false}
              maxTableHeight={250}
              sortFunction={assignDigitalTicketsStore.setParkingSortDirection}
              sortColumn={assignDigitalTicketsStore.parkingSortFilters["key"]}
              sortDirection={assignDigitalTicketsStore.parkingSortFilters["direction"]}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <PrimaryButton
                onClick={() => {
                  assignDigitalTicketsStore.setSending(true);
                  assignDigitalTicketsStore.forwardTickets();
                }}
                disabled={
                  assignDigitalTicketsStore.sending ||
                  assignDigitalTicketsStore.selectedSeats.length < assignDigitalTicketsStore.request.quantity
                }
              >
                Send Tickets
              </PrimaryButton>
              <LightButton
                onClick={() => {
                  assignDigitalTicketsStore.setShowAssignTicketsModal(false);
                  assignDigitalTicketsStore.setShowParking(false);
                  assignDigitalTicketsStore.resetStore();
                }}
              >
                Cancel
              </LightButton>
            </div>
            <div className="d-flex flex-row">
              <div className="refresh-accordion mt-1" id="refreshAccordion" style={{ marginBottom: 1 }}>
                <button
                  className="btn btn-link btn-block pl-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#refreshCollapse"
                  aria-expanded="false"
                  aria-controls="refreshCollapse"
                >
                  Not Seeing Tickets?
                </button>
                <div
                  id="refreshCollapse"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#refreshAccordion"
                >
                  <div className="d-flex flex-column">
                    <LightButton
                      onClick={() => {
                        assignDigitalTicketsStore.setAllowRefresh(false);
                        assignDigitalTicketsStore.refreshTickets();
                        this.disabled = true;
                      }}
                      disabled={!assignDigitalTicketsStore.allowRefresh}
                    >
                      Refresh Tickets
                    </LightButton>
                    <div>Tickets can be refreshed every 10 minutes</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(AssignDigitalTicketsModal));
