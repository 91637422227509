import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton,
  ButtonLink,
  BestTable
} from "best-common-react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import CheckFormatter from "../elements/CheckFormatter";
import LoadingSpinner from "../loading/LoadingSpinner";

const TicketCount = styled.span`
  color: ${props => (props.filled ? props.theme["green"] : props.theme["slate-grey"])};
  font-style: italic;
  font-size: 0.8rem;
`;

const TicketColumns = [
  {
    name: "Section",
    key: "section",
    sortable: false,
    width: 125
  },
  {
    name: "Row",
    key: "row",
    sortable: false,
    width: 100
  },
  {
    name: "Seat Number",
    key: "seat",
    sortable: false,
    width: 125
  }
];

const ParkingColumns = [
  {
    name: "Parking Lot",
    key: "section",
    sortable: false,
    width: 125
  },
  {
    name: "Price",
    key: "price",
    sortable: false,
    width: 125
  },
  {
    name: "Type",
    key: "provider",
    sortable: false,
    width: 100
  }
];

class RevokeDigitalTicketsModal extends React.Component {
  render() {
    const { revokeDigitalTicketsStore } = this.props.rootStore;

    return (
      <Modal
        show={revokeDigitalTicketsStore.showRevokeTicketsModal}
        onClose={() => {}}
        size="lg"
        id="revoke-digital-tickets-modal"
      >
        <ModalHeaderWithTitleAndClose
          title="Assigned tickets"
          close={() => {
            revokeDigitalTicketsStore.setShowRevokeTicketsModal(false);
            revokeDigitalTicketsStore.setShowParking(false);
            revokeDigitalTicketsStore.setAllTickets([]);
            revokeDigitalTicketsStore.setParkingTickets([]);
          }}
        />
        <ModalBody>
          <div>
            <b>From: </b> {revokeDigitalTicketsStore.request?.requesterUser?.lastName},
            {revokeDigitalTicketsStore.request?.requesterUser?.firstName}
          </div>
          <div>
            <b>To: </b> {revokeDigitalTicketsStore.request?.recipientLastName},
            {revokeDigitalTicketsStore.request?.recipientFirstName}
          </div>
          <LoadingSpinner size={10} />
          <div className="mt-1">
            <BestTable
              columns={TicketColumns}
              data={revokeDigitalTicketsStore.displayableTickets}
              rowDisabledKey="disabled"
              rowStylesGetter={(index, value) => {
                if (value?.disabled) {
                  return { background: "#f5f7fa" };
                } else {
                  return {};
                }
              }}
              selectAll={false}
              maxTableHeight={250}
            />
          </div>

          <div className="d-flex justify-content-end mt-2">
            <ButtonLink
              onClick={() => {
                revokeDigitalTicketsStore.toggleShowParkingPasses();
              }}
            >
              {revokeDigitalTicketsStore.showParkingPasses ? "Hide Parking Passes" : "Show Parking Passes"}
            </ButtonLink>
          </div>
          <div className="mt-2" hidden={!revokeDigitalTicketsStore.showParkingPasses}>
            <BestTable
              columns={ParkingColumns}
              data={revokeDigitalTicketsStore.parkingPasses?.toJSON()}
              rowDisabledKey="disabled"
              selectAll={false}
              maxTableHeight={250}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              onClick={() => {
                revokeDigitalTicketsStore.revokeTickets();
              }}
              disabled={false}
            >
              Revoke Tickets
            </PrimaryButton>
            <LightButton
              onClick={() => {
                revokeDigitalTicketsStore.setShowRevokeTicketsModal(false);
                revokeDigitalTicketsStore.setShowParking(false);
                revokeDigitalTicketsStore.setAllTickets([]);
                revokeDigitalTicketsStore.setParkingTickets([]);
              }}
            >
              Cancel
            </LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(RevokeDigitalTicketsModal));
